import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ConfigService } from "./config.service";
import { NotificacionesService } from "./notificaciones.service";
import { AccesoService } from "./acceso.service";
import { ActualizarPaginaService } from "./actualizar-pagina.service";
import { catchError, concatMap, mapTo, switchMap,retryWhen, tap,delay,take } from "rxjs/operators";
import { from, Observable, throwError } from "rxjs";

@Injectable()
export class HttpService {
  token;
  refreshToken;
  ultimoToken;

  constructor(private http: HttpClient, private configService: ConfigService,public actualizarPaginaService: ActualizarPaginaService, private notificacionService:NotificacionesService) {}

  init(token, tipo, urlRest, envio = null,notificacion = false): Observable<any> {
    let httpOptions;

    if (this.ultimoToken != undefined) {
      let minutosDiferencia = this.getMinutesBetweenDates(
        this.ultimoToken,
        new Date()
      );

      //if (minutosDiferencia >= 50 && minutosDiferencia < 6000) {
      if (minutosDiferencia >= 3) {
        return from(this.actualizarToken()).pipe( 
          concatMap(() => {
            if(!notificacion){
              this.notificacionService.notificarEvento();
            }
            return this.realizarSolicitud(token, tipo, urlRest, envio);
          }) 
        );
      }else{
        return this.realizarSolicitud(token, tipo, urlRest, envio);
      }
    }else{
      return this.realizarSolicitud(token, tipo, urlRest, envio);
    }
  }

  realizarSolicitud(token, tipo, urlRest, envio){
    let httpOptions;
    token
      ? (httpOptions = {
          headers: new HttpHeaders({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: " Bearer " + this.token
          })
        })
      : (httpOptions = {
          headers: new HttpHeaders({
            Accept: "application/json",
            "Content-Type": "application/json"
          })
        });
    // Remover el Content-Type si se está utilizando FormData 
    if (envio instanceof FormData) { 
      httpOptions.headers = httpOptions.headers.delete("Content-Type"); 
      httpOptions.headers = httpOptions.headers.delete("Accept");
    }
    switch (tipo) {
      case "post": {
        return this.http.post(
          this.configService.url + urlRest,
          envio,
          httpOptions
        ).pipe( catchError(error => this.handleTokenError(error, token, tipo, urlRest, envio)));
      }

      case "get": {
        return this.http.get(this.configService.url + urlRest, httpOptions).pipe( catchError(error => this.handleTokenError(error, token, tipo, urlRest, envio)));
      }
    }
  }


  async actualizarToken(): Promise<void> {
    let body = new URLSearchParams();
    body.set("grant_type", "refresh_token");
    body.set("refresh_token", this.refreshToken);

    let options = {
      headers: new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      )
    };

    const observable = this.http
      .post(
        this.configService.url + "/oauth/access_token",
        body.toString(),
        options
      ).pipe( 
        tap(data => { 
          this.token = data["access_token"]; 
          this.refreshToken = data["refresh_token"]; 
          this.ultimoToken = new Date();
          console.log("obtencion del token a traves del refreshToken (actualizarToken)");
        }), mapTo(void 0),
        catchError((err) => {
          console.log(err);
          new swal(
            {
              title: "Tu sesión se ha vencido",
              text: "Intentaremos loguearlo de manera automática",
              type: "warning",
              showCancelButton: false,
              confirmButtonClass: "btn-info",
              confirmButtonText: "Aceptar",
              closeOnConfirm: false,
            },
            (isConfirm) => {
              if (isConfirm) {          
                this.actualizarPaginaService.quitarAlerta();
                window.location.reload();
              } else {
                this.actualizarPaginaService.quitarAlerta();
                window.location.reload();
              }
            }
          );
          return throwError(err); // Asegura que un Observable es retornado
        })
        );
        return await observable.toPromise();
  }

  handleTokenError(error: any, token: any, tipo: any, urlRest: any, envio: any): Observable<any> { 
    if (error.status === 401 && token) { 
      // Token inválido, intenta refrescar el token 
      return from(this.actualizarToken()).pipe( 
        switchMap(() => this.realizarSolicitud(token, tipo, urlRest, envio)),
        retryWhen(errors => 
          errors.pipe(
            delay(1000), // Espera 1 segundo antes de reintentar
            take(3), // Limita los intentos a 3
            catchError((err) => {
              console.log('Se han agotado los intentos de reintento.', err);
              new swal({
                title: "Tu sesión se ha vencido",
                text: "Intentaremos loguearlo de manera automática",
                type: "warning",
                showCancelButton: false,
                confirmButtonClass: "btn-info",
                confirmButtonText: "Aceptar",
                closeOnConfirm: false,
              }, (isConfirm) => {
                this.actualizarPaginaService.quitarAlerta();
                window.location.reload();
              });
              return throwError(err); // Asegura que un Observable es retornado
            })
          )
        ),
        catchError((err) => {
          console.log('Error al refrescar el token.', err);
          return throwError(err);
        })
      ); 
    } else { 
      console.log('Error, el enpoint no usa token o es otro error != 401.', error);
      return throwError(error);
    } 
  }
    

  getMinutesBetweenDates(startDate, endDate) {
    var diff = endDate.getTime() - startDate.getTime();
    return diff / 60000;
  }
}
