import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
export class ActualizarPaginaService {
  alerta = function(e) {
    var confirmationMessage =
      "¿Seguro desea continuar? " +
      "Perderá los cambios no guardados y deverá volver a loguearse...";
    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  };

  constructor(public router: Router) {}

  agregarAlerta() {
    window.addEventListener("beforeunload", this.alerta);
  }

  quitarAlerta() {
    window.removeEventListener("beforeunload", this.alerta);
  }

  actualizarPagina() {
    this.quitarAlerta();
    
    localStorage.clear();
    this.router.navigate(["/login"]);
    //location.reload();
  }

  tiempoAgotado() {
    this.actualizarPagina();
  }
}
