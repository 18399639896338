import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import { AccesoService } from "./acceso.service";
import { FormaPago } from "../models/formaPago";
import {
  Cobro
} from "../models/cobro";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { UsuarioService } from "../services/usuario.service";
import { HttpService } from "./http.service";
import { EmpresaService } from "./empresa.service";
import { Router } from "@angular/router";
import { UtilidadesService } from "./utilidades.service";

@Injectable()
export class FormasDePagoService {
  constructor(
    private utilidadesService: UtilidadesService,
    private router: Router,
    private empresaService: EmpresaService,
    private usuarioService: UsuarioService,
    private http: HttpService,
    private config: ConfigService,
    private accesoService: AccesoService,
    private spinnerService: Ng4LoadingSpinnerService,
    private configService: ConfigService
  ) {}

  kolektiMpClientId;
  codeMp;
  nuevoCobroRapido: Cobro;
  formaPagoActual: FormaPago = {};
  formaPagoList: Array<FormaPago>;
  formaPagoListEnabled = [];
  formaPagoListDisabled = [];
  urlLogoFormaPago: string;

  formasPago: FormaPago[] = [
    { id: 1, nombre: "Efectivo", logoUrl: "assets/formasPago/efectivo.svg" },
    //Prueba ocultando Forma de pago Tarjeta (POS Virtual)
    /*{ id: 11, nombre: "Tarjeta", logoUrl: "assets/formasPago/tarjeta.svg" },*/
    /*{ id: 12, nombre: "MP Point", logoUrl: "assets/formasPago/point.svg" },
    { id: 3, nombre: "MP QR", logoUrl: "assets/formasPago/qr.svg" },*/
    { id: 6, nombre: "Cheque", logoUrl: "assets/formasPago/cheque.svg" },
    { id: 5, nombre: "Voucher", logoUrl: "assets/formasPago/voucher.svg" }
  ];

  formaPagoNombreRuta = {
    1: "efectivo",
    2: "debin",
    3: "mercadoPago",
    4: "todopago",
    5: "pps",
    6: "cheque",
    7: "pim",
    8: "gwallet",
    9: "lapos",
    10: "valpei",
    11: "posVirtual",
    12: "point",
    13: "cuenta",
    14: "cupon",
    15: "link"
  };
  cobroMultiple;

  obtenerListadoFormaPago() {
    return this.http.init(true, "get", "/rest/wsObtenerListadoFormaPago");
  }

  comprobarCredencialesMp() {
    return this.http.init(true, "post", "/rest/wsValidarCredencialesMp", {
      empresa: this.empresaService.empresa
    });
  }

  cargarLogoFormaPago(formaPago: FormaPago) {
    return this.config.url + formaPago.logoName;
  }

  crearUrlLogos() {
    let i = 0;
    for (let formaPago of this.formaPagoList) {
      let urlLogo = this.cargarLogoFormaPago(formaPago);
      this.formaPagoList[i].logoUrl = urlLogo;

      i++;
    }
  }

  cargarLinkFormaPago(formaPago) {
    return "/backoffice/cobro/" + this.formaPagoNombreRuta[formaPago.id];
  }

  cargarLinkFormaPagoCM(formaPago) {
    return (
      "/backoffice/cobro-multiple/cobrar/" +
      this.formaPagoNombreRuta[formaPago.id]
    );
  }

  filtrarFormaPago() {
    this.formaPagoListEnabled = [];
    this.formaPagoListDisabled = [];

    if (this.utilidadesService.autogestion) {
      for (let formaPago of this.formaPagoList) {
        // if (formaPago.id == 11 || formaPago.id == 3 || formaPago.id == 7) {
        //   this.formaPagoListEnabled.push(formaPago);
        // }

        if (formaPago.id == 11 && this.utilidadesService.autogestionMP) {
          this.formaPagoListEnabled.push(formaPago);
        }

        // if (formaPago.id == 7 && this.utilidadesService.autogestionPim) {
        //   this.formaPagoListEnabled.push(formaPago);
        // }
      }
      this.formaPagoListEnabled.reverse();
    } else {
      for (let formaPago of this.formaPagoList) {
        if (
          formaPago.id == 1 ||
          formaPago.id == 11 ||
          formaPago.id == 3 ||
          formaPago.id == 5 ||
          formaPago.id == 6 ||
          formaPago.id == 12 
        ) {
          if (this.cobroMultiple) {
            if (formaPago.id != 12) {
              this.formaPagoListEnabled.push(formaPago);
            }
          } else {
            this.formaPagoListEnabled.push(formaPago);
          }
        } else {
          this.formaPagoListDisabled.push(formaPago);
        }
      }
    }
  }

  setFormaPagoActual(event) {
    this.formaPagoActual = JSON.parse(event.target.name);
  }

  activarCredencialesMp(code, datos) {
    let url = this.configService.urlMp + "?datos=" + datos;
    let datosParseados = JSON.parse(datos);

    return this.http.init(
      false,
      "post",
      "/rest/wsMpObtenerCredencialesAutorizadas",
      {
        empresa: datosParseados["empresa"],
        usuario: datosParseados["usuario"],
        code: code,
        redirectUri: url
      }
    );
  }
}
