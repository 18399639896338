import { EventEmitter, Injectable, NgZone } from "@angular/core";
import { UsuarioService } from "../services/usuario.service";
import { VerService } from "../services/ver.service";
import { UtilidadesService } from "../services/utilidades.service";
import { Router } from "@angular/router";
import { ConfigService } from "./config.service";
import { AccesoService } from "../services/acceso.service";
import { ListarService } from "./listar.service";
import { DatatableService } from "./datatable.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { HttpService } from "./http.service";
import { EmpresaService } from "./empresa.service";


@Injectable()
export class RolService {
  rolList = [];
  permissionList = [];
  rolActual;
  filtro = {
    activo: false,
    campos: { fechaDesde: "", fechaHasta: "", authority: "" }
  };
  datosCargados;
  RolEvents  =  new EventEmitter<string>();

  constructor(
    private zone: NgZone,
    private empresaService: EmpresaService,
    private http: HttpService,
    private spinnerService: Ng4LoadingSpinnerService,
    private listarService: ListarService,
    private utilidadesService: UtilidadesService,
    private router: Router
  ) {
    window["rolService"] = {
      zone: this.zone,
      componentFn: value => this.eliminar(value),
      componentFn2: () =>
        this.router.navigate(["backoffice/administracion/roles"]),
      component: this
    };
  }

  obtenerPermisosCrearRol() {
    return this.http.init(
      true,
      "get",
      "/rest/wsObtenerListadoPermissionUsuarioLogueado"
    );
  }
  
  cargarRol(rol) {
    return this.http.init(true, "post", "/rest/wsGenerarRole", {
      empresa: this.empresaService.empresa,
      rol: rol
    });
  }

  listar() {
    this.listarService.paginaActual = "rolService";
    this.listarService.titulo = "Roles";
    this.listarService.singular = "Rol";
    this.router.navigate(["/backoffice/administracion/roles"]);
  }

  obtenerRol(empresa, rol) {
    this.datosCargados = false;
    this.http
      .init(true, "post", "/rest/wsObtenerDatosRolePorEmpresa", {
        empresa: this.empresaService.empresa,
        rol: rol
      })
      .subscribe(
        data => {
          if (this.utilidadesService.dataErrorHandler(data)) {
            let rol = data["roleInstance"];
            rol["permission"] = data["roleInstanceComplemento"]["permission"];
            rol["usuarioAudit"] =
              data["roleInstanceComplemento"]["usuarioAudit"];
            this.rolActual = rol;
            this.datosCargados = true;
          }
        },
        error => {
          this.utilidadesService.errorHandler(error);
        }
      );
  }

  actualizarRol(rol) {
    return this.http.init(true, "post", "/rest/wsActualizarRole", {
      empresa: this.empresaService.empresa,
      rol: rol
    });
  }

  filtrar() {}

  eliminarRol(rol) {
    swal(
      {
        title: "¿Estás seguro que deseas realizar esta acción?",
        text: "No podrás recuperar el elemento eliminado.",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Si, deseo eliminarlo!",
        closeOnConfirm: false
      },
      function(isConfirm) {
        if (isConfirm) {
          window["rolService"].zone.run(() => {
            window["rolService"].componentFn(rol);
          });
        } else {
          return false;
        }
      }
    );
  }
 
  eliminar(rol) {
    this.spinnerService.show();
    this.http
      .init(true, "post", "/rest/wsEliminarRole", {
        empresa: this.empresaService.empresa,
        rol: rol
      })
      .subscribe(
        data => {
          this.spinnerService.hide();
          if (
            data["responseCode"] != "200" &&
            data["responseCode"] != "201" &&
            data["responseCode"] != "202"
          ) {
            new swal(
              "Por favor, intenta nuevamente.",
              data["responseMsg"],
              "error"
            );
         
          } else {
            new swal("Rol eliminado con éxito.", "", "success");
            this.RolEvents.emit("eliminar");
          
           
            this.router.navigate(["/backoffice/administracion/roles"]);
          }
        },

        error => {
          this.spinnerService.hide();
          this.utilidadesService.errorHandler(error);
          
        }
      );
  }

  obtenerRolesEmpresa() {
    return this.http.init(
      true,
      "post",
      "/rest/wsObtenerListadoRolePorEmpresa",
      { empresa: this.empresaService.empresa, filtro: this.filtro }
    );
  }

  completarPermisos() {
    let list = [];
    for (let rol of this.rolList) {
      rol["roleInstance"]["permission"] =
        rol["roleInstanceComplemento"]["permission"];
      list.push(rol["roleInstance"]);
    }
    this.rolList = list;
  }

  setRolActual(event) {
    let input = $(event.target).find("input");
    let rol:any = input.val();

    this.rolActual = JSON.parse(rol);
  }
}
