import { Component, OnInit } from "@angular/core";
import { fadeInAnimation } from "../../../animations/animations";
import { ScriptService } from "../../../services/script.service";
import { LoginService } from "../../../services/login.service";
import { UtilidadesService } from "../../../services/utilidades.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";

@Component({
  selector: "app-resetear-clave-a",
  templateUrl: "./resetear-clave-a.component.html",
  styleUrls: ["./resetear-clave-a.component.css"],
  animations: [fadeInAnimation]
})
export class ResetearClaveAComponent implements OnInit {
  animation = true;
  email;
  dynamicScripts = [
    "./assets/global/plugins/jquery-validation/js/jquery.validate.min.js",
    "./assets/global/plugins/jquery-validation/js/additional-methods.min.js",
    "./assets/components/login/js/login.component.js",
    "./assets/global/plugins/jquery-validation/js/localization/messages_es_AR.js"
  ];

  constructor(
    private scriptService: ScriptService,
    private loginService: LoginService,
    public utilidadesService: UtilidadesService,
    private spinnerService: Ng4LoadingSpinnerService
  ) {}

  ngOnInit() {
    //CARGAR SCRIPTS
    new Promise(resolve => {
      this.scriptService.loadScripts(this.dynamicScripts);
      resolve(true);
    });
    this.validar();
  }

  resetear() {
    $("#email").val(($("#email") as any).val().trim());
    if (($("#loginForm") as any).valid()) {
      this.email = this.email.trim();
      this.spinnerService.show();
      this.loginService.solicitarReseteoClave(this.email).subscribe(
        data => {
          this.spinnerService.hide();
          if (this.utilidadesService.dataErrorHandler(data)) {
            new swal(
              "Solicitud exitosa",
              "Te acabamos de enviar un email a la cuenta " +
                this.email +
                ", sigue las instrucciones para modificar tu clave.",
              "success"
            );
          }
        },
        error => {
          this.spinnerService.hide();
          this.utilidadesService.errorHandler(error);
        }
      );
    }
  }

  validar() {
    var form = $("#loginForm");
    let rules = {
      email: {
        required: true,
        email: true
      }
    };
    validarFormulario(form, rules);
  }
}
