import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { TestComponent } from "./components/test/test.component";
import { Ng4LoadingSpinnerModule } from "ng4-loading-spinner";
import { Router } from "@angular/router";
import { AccesoService } from "./services/acceso.service";
import { AccesoGuard } from "./acceso.guard";
import { CargarEmpresaComponent } from "./components/cargar-empresa/cargar-empresa.component";
import { ConfigService } from "./services/config.service";
import { HttpClientModule } from "@angular/common/http";
import { UsuarioService } from "./services/usuario.service";
import { RolService } from "./services/rol.service";
import { ScriptService } from "./services/script.service";
import { UtilidadesService } from "./services/utilidades.service";

import { CommonModule } from "@angular/common";

import { VerService } from "./services/ver.service";
import { DatatableService } from "./services/datatable.service";
import { CuentaService } from "./services/cuenta.service";
import { HttpService } from "./services/http.service";
import { FormasDePagoService } from "./services/formas-de-pago.service";
import { ModalsService } from "./services/modals.service";
import { ListarService } from "./services/listar.service";
import { ETarjetaService } from "./services/e-tarjeta.service";
import { EmpresaService } from "./services/empresa.service";
import { ClienteService } from "./services/cliente.service";
import { RegistroCuentaComponent } from "./components/registro-cuenta/registro-cuenta.component";

import { CajaService } from "./services/caja.service";
import { CobroService } from "./services/cobro.service";
import { NgxMaskModule } from "ngx-mask";
import { ModalPostVirtualComponent } from "./components/modal-post-virtual/modal-post-virtual.component";
import { UtilidadesModule } from "./utilidades/utilidades.module";
import { MaterialModule } from ".//material/material.module";
import { ActualizarPaginaService } from "./services/actualizar-pagina.service";
import { ModalPostVirtual2Component } from "./components/modal-post-virtual/modal-post-virtual2/modal-post-virtual2.component";
import { PimService } from "./services/cobros/pim.service";
import { CalculosCierreCajaService } from "./services/calculos-cierre-caja.service";
import { CobroMultipleService } from "./services/cobro-multiple.service";
import { AutorizarCredencialesMpComponent } from "./components/back-office/back-office-content/administracion/empresa/empresa-ver/autorizar-credenciales-mp/autorizar-credenciales-mp.component";
import { LoginService } from "./services/login.service";
import { ResetearClaveAComponent } from "./components/resetear-clave/resetear-clave-a/resetear-clave-a.component";
import { ResetearClaveBComponent } from "./components/resetear-clave/resetear-clave-b/resetear-clave-b.component";
import { ModalPointComponent } from "./components/back-office/back-office-content/cobros/cobro-rapido/cobro-layout/point/modal-point/modal-point.component";
import { CuentaCorrienteService } from "./services/cuenta-corriente.service";
import { ModalPostVirtual3Component } from "./components/modal-post-virtual/modal-post-virtual3/modal-post-virtual3.component";
import { RevisarEmailComponent } from "./components/registro-cuenta/revisar-email/revisar-email.component";
import { AutogestionModule } from "./autogestion/autogestion.module";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/es-AR";
import { ModalFacturacionComponent } from "./components/back-office/back-office-content/facturacion/modal-facturacion/modal-facturacion.component";
import { ReseteoClavePaso2Component } from "./components/login/resetear-clave/reseteo-clave-paso2/reseteo-clave-paso2.component";
import { BasicComponentsModule } from "./basic-components/basic-components.module";
import { ModalBienvenidaComponent } from './components/modal-bienvenida/modal-bienvenida.component';
import { CargandoPrincipalComponent } from './components/cargando-principal/cargando-principal.component';;
import { UrlSeguroPipe } from './pipes/url-seguro.pipe';
//import * as bootstrap from "bootstrap";
//import 'bootstrap'; 
//import * as $ from 'jquery';
registerLocaleData(localeFr, "es-AR");

@NgModule({
  declarations: [
    AppComponent,

    TestComponent,
    CargarEmpresaComponent,

    RegistroCuentaComponent,

    ModalPostVirtualComponent,
    ModalPostVirtual2Component,
    AutorizarCredencialesMpComponent,
    ResetearClaveAComponent,
    ModalPointComponent,
    ModalPostVirtual3Component,
    RevisarEmailComponent,
    ResetearClaveBComponent,
    ModalBienvenidaComponent,
    CargandoPrincipalComponent,
    UrlSeguroPipe,
    
    // QrTemplateComponent
  ],
  imports: [
    CommonModule,
    UtilidadesModule,
    BasicComponentsModule,
    AutogestionModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    Ng4LoadingSpinnerModule.forRoot(),
    NgxMaskModule.forRoot(),
    HttpClientModule,
    MaterialModule,
    ServiceWorkerModule.register("ngsw-worker.js",{
      enabled: environment.production
    })
  ],
  providers: [
    FormasDePagoService,
    AccesoService,
    AccesoGuard,
    ConfigService,
    UsuarioService,
    UtilidadesService,
    CuentaService,
    VerService,
    DatatableService,
    RolService,
    HttpService,
    ModalsService,
    ListarService,
    ETarjetaService,
    EmpresaService,
    ClienteService,
    ScriptService,
    CajaService,
    CobroService,
    ActualizarPaginaService,
    PimService,
    CobroMultipleService,
    CalculosCierreCajaService,
    LoginService,
    CuentaCorrienteService,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router) {}
}
