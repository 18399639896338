
import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from "rxjs/operators";
import { HttpService } from "./http.service";
@Injectable({
  providedIn: "root",
})
export class NotificacionesService {
  private http: HttpService;
  private notificacionObserver = new Subject<any>();
  public notificacionObservable = this.notificacionObserver.asObservable();
  notificaciones = [];
  constructor(private injector: Injector){
    setTimeout(() => { 
        this.http = this.injector.get(HttpService); 
    });
  }

  obtenerNotificaciones(idEmpresa) {
    return this.http.init(
      true,
      "post",
      "/rest/wsObtenerNotificacionesInApp",
      { empresa: {id:idEmpresa} },
      true
    ).pipe(map((data:any) =>{
      this.notificaciones = this.notificaciones.filter(
        (o) => o.tipo != 'notificacion'
      );
      for (var i = 0; i < data.notificacionesInApp.length; i++) {
        var fechaFormateada = this.obtenerFechaFormateada(data.notificacionesInApp[i].creado);
        this.notificaciones.push({
          id: data.notificacionesInApp[i].id,
          tituloCorto: data.notificacionesInApp[i].tituloCorto,
          mensaje: data.notificacionesInApp[i].mensaje,
          tipo: "notificacion",
          leido: false,
          vencimiento: data.notificacionesInApp[i].vencimiento,
          creado: fechaFormateada
        });
      }      
      this.verificarNotificaciones();
      return data;
    }));
    /*let notificacionesJSON = JSON.parse(jsonNotificaciones).notificacionesList;
    this.notificaciones = this.notificaciones.filter(
      (o) => o.tipo != 'notificacion'
    );
      this.notificaciones.push(...notificacionesJSON);*/
    /*if(!localStorage.getItem('notificaciones')){
      localStorage.setItem('notificaciones',JSON.stringify(this.notificaciones.filter(notificacion => notificacion.tipo == 'notificacion')));
    }else{
    }*/



    /*return this.http.init(
      true,
      "post",
      "/rest/wsObtenerListadoProvinciaPorPais",
      { pais: pais }
    );*/
  }

  verificarNotificaciones(){
    const fechaActual = new Date();
    const fechaVencimiento = new Date(fechaActual);
    fechaVencimiento.setDate(fechaVencimiento.getDate() );
    const timestamp = Math.floor(fechaVencimiento.getTime() / 1000);
    var notificacionesLocalStorage = [];
    //JSON.parse(localStorage.getItem('notificaciones'));
    if(localStorage.getItem('notificaciones')){
      notificacionesLocalStorage = JSON.parse(localStorage.getItem('notificaciones'));
      notificacionesLocalStorage = notificacionesLocalStorage.filter(notificacion=>{
        if((Date.parse(notificacion.vencimiento) /1000) > timestamp){
          return true;
        }else{
          return false;
        }
      });
      localStorage.setItem('notificaciones',JSON.stringify(notificacionesLocalStorage));
    }
    this.notificaciones = this.notificaciones.filter(notificacion => !notificacionesLocalStorage.some(notificacionLocalStorage => notificacion.id === notificacionLocalStorage.id));
    
    /*if(this.notificaciones){
      localStorage.setItem('notificaciones',JSON.stringify(this.notificaciones));
    }
    
    localStorage.removeItem('notificaciones');*/
  }

  notificarEvento(){
    this.notificacionObserver.next();
  }

  obtenerFechaFormateada(fechaOriginal){
    const fecha = new Date(fechaOriginal);
    const dia = fecha.getDate().toString().padStart(2, "0");
    const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const anio = fecha.getFullYear().toString();
    const fechaFormateada = `${dia}/${mes}/${anio}`;
    return fechaFormateada;
  }
}
